import { Link } from 'gatsby';
import Response from "../../../../src/components/response";
import SEO from "../../../../src/components/seo";
import Code from "../../../../src/components/code";
import * as React from 'react';
export default {
  Link,
  Response,
  SEO,
  Code,
  React
};