// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-continue-mdx": () => import("./../../../src/pages/continue.mdx" /* webpackChunkName: "component---src-pages-continue-mdx" */),
  "component---src-pages-discovery-mdx": () => import("./../../../src/pages/discovery.mdx" /* webpackChunkName: "component---src-pages-discovery-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-interaction-mdx": () => import("./../../../src/pages/interaction.mdx" /* webpackChunkName: "component---src-pages-interaction-mdx" */),
  "component---src-pages-keys-mdx": () => import("./../../../src/pages/keys.mdx" /* webpackChunkName: "component---src-pages-keys-mdx" */),
  "component---src-pages-request-mdx": () => import("./../../../src/pages/request.mdx" /* webpackChunkName: "component---src-pages-request-mdx" */),
  "component---src-pages-response-mdx": () => import("./../../../src/pages/response.mdx" /* webpackChunkName: "component---src-pages-response-mdx" */),
  "component---src-pages-specs-mdx": () => import("./../../../src/pages/specs.mdx" /* webpackChunkName: "component---src-pages-specs-mdx" */),
  "component---src-pages-tokens-mdx": () => import("./../../../src/pages/tokens.mdx" /* webpackChunkName: "component---src-pages-tokens-mdx" */)
}

